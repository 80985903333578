import {
  createWebHistory,
  createRouter
} from "vue-router";
import {
  defineAsyncComponent
} from "vue";

const MainView = defineAsyncComponent(() => import("@/views/main.vue"));
const Login = defineAsyncComponent(() => import("@/views/Login/login.vue"));

const Home = defineAsyncComponent(() => import("@/views/Home/home.vue"));

const UserProfile = defineAsyncComponent(() =>
  import("@/views/User/profile.vue")
);

const Admin = defineAsyncComponent(() => import("@/views/Admin/admin.vue"));

const AddAdmin = defineAsyncComponent(() =>
  import("@/views/Admin/add-admin.vue")
);

const ManageUrl = defineAsyncComponent(() => import("@/views/ManageUrl/manage-url.vue"));

const CustomerManagement = defineAsyncComponent(() => import("@/views/ManageCustomer/customer-management.vue"));

const EditCustomerManagement = defineAsyncComponent(() => import("@/views/ManageCustomer/edit-customer-management.vue"));

const ViewCustomerManagement = defineAsyncComponent(() => import("@/views/ManageCustomer/view-customer-management.vue"));


const NoteDetails = defineAsyncComponent(() => import("@/views/ManageCustomer/note-details.vue"));

const RecipeDetails = defineAsyncComponent(() => import("@/views/ManageCustomer/recipe-details.vue"));







const Event = defineAsyncComponent(() => import("@/views/Event/event.vue"));

const AddEvent = defineAsyncComponent(() =>
  import("@/views/Event/edit-event.vue")
);

const EditEvent = defineAsyncComponent(() =>
  import("@/views/Event/edit-event.vue")
);



const ChangePassword = defineAsyncComponent(() =>
  import("@/views/User/change-password.vue")
);

const EditWord = defineAsyncComponent(() =>
  import("@/views/Bannedword/edit-word.vue")
);

const AddCountry = defineAsyncComponent(() =>
  import("@/views/Country/add-country.vue")
);

const EditCountry = defineAsyncComponent(() =>
  import("@/views/Country/add-country.vue")
);




const Article = defineAsyncComponent(() => import("@/views/Article/article.vue"));


const EditArticle = defineAsyncComponent(() =>
  import("@/views/Article/edit-article.vue")
);

const AddArticle = defineAsyncComponent(() =>
  import("@/views/Article/edit-article.vue")
);





const EditState = defineAsyncComponent(() =>
  import("@/views/State/edit-state.vue")
);



const EditVerificationRequest = defineAsyncComponent(() =>
  import("@/views/VarificationRequest/edit-varification-request.vue")
);


const EditCity = defineAsyncComponent(() =>
  import("@/views/City/edit-city.vue")
);

const TagRequest = defineAsyncComponent(() => import("@/views/TagRequest/tag-request.vue"));

const EditTagRequest = defineAsyncComponent(() =>
  import("@/views/TagRequest/edit-tag-request.vue")
);


const VerificationRequest = defineAsyncComponent(() => import("@/views/VarificationRequest/varification-request.vue"));

const DataBackupRequest = defineAsyncComponent(() => import("@/views/DataBackupRequest/data-backup-request.vue"));




const Tags = defineAsyncComponent(() => import("@/views/Tag/tag.vue"));

const Report = defineAsyncComponent(() => import("@/views/Report/report.vue"));

const Club = defineAsyncComponent(() => import("@/views/Club/club.vue"));

const ViewClub = defineAsyncComponent(() =>
  import("@/views/Club/view-club.vue")
);


const EditTag = defineAsyncComponent(() =>
  import("@/views/Tag/edit-tag.vue")
);


const EditReport = defineAsyncComponent(() =>
  import("@/views/Report/edit-report.vue")
);

const Countires = defineAsyncComponent(() => import("@/views/Country/country.vue"));


const States = defineAsyncComponent(() => import("@/views/State/state.vue"));

const Cities = defineAsyncComponent(() => import("@/views/City/city.vue"));


const Notifications = defineAsyncComponent(() => import("@/views/Notifications/notifications.vue"));


const AdminNotifications = defineAsyncComponent(() => import("@/views/AdminNotifications/admin-notification-list.vue"));


const EditNotifications = defineAsyncComponent(() =>
  import("@/views/Notifications/edit-notifications.vue")
);


const BannedWord = defineAsyncComponent(() =>
  import("@/views/Bannedword/banned-word.vue")
);

const Subscription = defineAsyncComponent(() =>
  import("@/views/Subscription/subscription.vue")
);

const EditSubscription = defineAsyncComponent(() =>
  import("@/views/Subscription/edit-subscription.vue")
);



const EditStaticPages = defineAsyncComponent(() =>
  import("@/views/Static Page/edit-static-page.vue")
);


const StaticPage = defineAsyncComponent(() =>
  import("@/views/Static Page/static-page.vue")
);
const Roles = defineAsyncComponent(() => import("@/views/Roles/roles.vue"));

const AddRole = defineAsyncComponent(() =>
  import("@/views/Roles/add-role.vue")
);


const ForgotPassword = defineAsyncComponent(() =>
  import("@/views/Login/forgot-password.vue")
);

const PrivacyPolicy = defineAsyncComponent(() =>
  import("@/views/PrivacyPolicy/PrivacyPolicy.vue")
);

const VerifyOtp = defineAsyncComponent(() =>
  import("@/views/Login/verify-otp.vue")
);

const Support = defineAsyncComponent(() =>
  import("@/views/Support/support.vue")
);

const ViewSupport = defineAsyncComponent(() =>
  import("@/views/Support/view-support.vue")
);
const Recipes = defineAsyncComponent(() =>
  import("@/views/Recipes/recipes.vue")
);

const ManageSplashScreen = defineAsyncComponent(() =>
  import("@/views/ManageSplashScreen/manage-splash-screen.vue")
);

const EditManageSplashScreen = defineAsyncComponent(() =>
  import("@/views/ManageSplashScreen/edit-manage-splash-screen.vue")
);

const ManageContent = defineAsyncComponent(() =>
  import("@/views/ManageContent/manage-content.vue")
);

const EditManageContent = defineAsyncComponent(() =>
  import("@/views/ManageContent/edit-manage-content.vue")
);


const routes = [{
  path: "/",
  redirect: Login,

},
{
  path: "/login",
  name: "Login",
  component: Login

},
{
  path: "/verify/otp",
  name: "VerifyOtp",
  component: VerifyOtp

},
{
  path: "/forgot-password",
  name: "ForgotPassword",
  component: ForgotPassword,
},
{
  path: "/privacy-policy",
  name: "PrivacyPolicy",
  component: PrivacyPolicy,
},

{
  path: "",
  name: "Main",
  component: MainView,
  meta: {
    requiresAuth: true,
  },
  children: [{
    path: "/customer-management",
    name: "CustomerManagement",
    component: CustomerManagement,
  },
  {
    path: "/manage-url",
    name: "ManageUrl",
    component: ManageUrl,
  },

  {
    path: "/edit-customer-management/:uuid",
    name: "EditCustomerManagement",
    component: EditCustomerManagement,
  },

  {
    path: "/view-customer-management/:uuid",
    name: "ViewCustomerManagement",
    component: ViewCustomerManagement,
  },

  {
    path: "/note/details/:uuid",
    name: "NoteDetails",
    component: NoteDetails,
  },

  {
    path: "/recipe/details/:uuid",
    name: "RecipeDetails",
    component: RecipeDetails,
  },


  {
    path: "/profile",
    name: "User Profile",
    component: UserProfile,
  },

  {
    path: "/home",
    name: "Home",
    component: Home,
  },

  {
    path: "/admins",
    name: "Admin",
    component: Admin,
  },


  {
    path: "/add-event",
    name: "AddEvent",
    component: AddEvent,
  },


  {
    path: "/Event",
    name: "Event",
    component: Event,
  },

  {
    path: "/edit-event/:uuid",
    name: "EditEvent",
    component: EditEvent,
  },


  {
    path: "/add-article",
    name: "AddArticle",
    component: AddArticle,
  },


  {
    path: "/Article",
    name: "Article",
    component: Article,
  },



  {
    path: "/edit-article/:uuid",
    name: "EditArticle",
    component: EditArticle,
  },


  {
    path: "/add-admin",
    name: "AddAdmin",
    component: AddAdmin,
  },


  {
    path: "/update-admin/:uuid",
    name: "UpdateAdmin",
    component: AddAdmin,
  },

  {
    path: "/tag",
    name: "Tag",
    component: Tags,
  },

  {
    path: "/add-tag/:type/add",
    name: "AddTag",
    component: EditTag,
  },

  {
    path: "/edit-tag/:type/edit/:uuid",
    name: "EditTag",
    component: EditTag,
  },

  {
    path: "/club",
    name: "Club",
    component: Club,
  },

  {
    path: "/view-club/:uuid",
    name: "ViewClub",
    component: ViewClub,
  },

  {
    path: "/report",
    name: "Report",
    component: Report,
  },

  {
    path: "/edit-report/:type/edit/:uuid",
    name: "EditReport",
    component: EditReport,
  },

  {
    path: "/country",
    name: "Country",
    component: Countires,
  },

  {
    path: "/add-country",
    name: "AddCountry",
    component: AddCountry,
  },
  {
    path: "/edit-country/:uuid",
    name: "EditCountry",
    component: EditCountry,
  },

  {
    path: "/state/:uuid",
    name: "State",
    component: States,
  },

  {
    path: "/add-state/:country_id/add",
    name: "AddState",
    component: EditState,
  },

  {
    path: "/edit-state/:country_id/edit/:uuid",
    name: "EditState",
    component: EditState,
  },

  {
    path: "/city/:uuid",
    name: "City",
    component: Cities,
  },

  {
    path: "/add-city/:state_id/add",
    name: "AddCity",
    component: EditCity,
  },

  {
    path: "/edit-city/:state_id/edit/:uuid",
    name: "EditCity",
    component: EditCity,
  },





  {
    path: "/subscription",
    name: "Subscription",
    component: Subscription,
  },

  {
    path: "/edit-subscription/:uuid",
    name: "EditSubscription",
    component: EditSubscription,
  },




  {
    path: "/banned-word",
    name: "BannedWord",
    component: BannedWord,
  },

  {
    path: "/add-word",
    name: "AddWord",
    component: EditWord,
  },
  {
    path: "/update-word/:uuid",
    name: "EditWord",
    component: EditWord,
  },

  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },




  {
    path: "/static_pages",
    name: "StaticPage",
    component: StaticPage,
  },
  {
    path: "/edit-static-page/:id",
    name: "AddStaticPage",
    component: EditStaticPages,
  },



  {
    path: "/roles",
    name: "Roles",
    component: Roles,
  },
  {
    path: "/add-role",
    name: "AddRole",
    component: AddRole,
  },
  {
    path: "/update-role",
    name: "UpdateRole",
    component: AddRole,
  },
  {
    path: "/tag-request",
    name: "TagRequest",
    component: TagRequest,
  },

  {
    path: "/edit-tag-request/edit/:uuid",
    name: "EditTagRequest",
    component: EditTagRequest,
  },

  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },

  {
    path: "/Admin-notifications",
    name: "AdminNotifications",
    component: AdminNotifications,
  },


  {
    path: "/notification/add",
    name: "AddNotifications",
    component: EditNotifications,
  },

  {
    path: "/notification/edit/:uuid",
    name: "EditNotifications",
    component: EditNotifications,
  },

  {
    path: "/verification-request",
    name: "VarificationRequest",
    component: VerificationRequest,
  },

  {
    path: "/edit-verification-request/edit/:uuid",
    name: "EditVarificationRequest",
    component: EditVerificationRequest,
  },


  {
    path: "/support",
    name: "Support",
    component: Support,
  },

  {
    path: "/view-support/:uuid",
    name: "ViewSupport",
    component: ViewSupport,
  },

  {
    path: "/manage-splash-screen",
    name: "ManageSplashScreen",
    component: ManageSplashScreen,
  },

  {
    path: "/data-backup-request",
    name: "DataBackupRequest",
    component: DataBackupRequest,
  },


  {
    path: "/edit-manage-splash-screen/:uuid",
    name: "EditManageSplashScreen",
    component: EditManageSplashScreen,
  },

  {
    path: "/manage-content",
    name: "ManageContent",
    component: ManageContent,
  },

  {
    path: "/recipes",
    name: "Recipes",
    component: Recipes,
  },
  {
    path: "/edit-manage-content/:uuid",
    name: "EditManageContent",
    component: EditManageContent,
  },

  ],
},
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token") == null) {
      next({
        path: "/login",
      });
    } else {
      if (to.path === "/") {
        if (localStorage.getItem("access_token") != null) {
          next({
            path: "/home",
          });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    if (to.path === "/" || to.path === "/login") {
      if (localStorage.getItem("access_token") != null) {
        console.log("redirect dashboard ");
        next({
          path: "/home",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;