import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./services/WebService";
import { IS_ADMIN } from "./services/WebService";
import helperService from "./services/HelperService";
import shareService from './services/ShareService'
import store from "./store/store";
import "element-plus/dist/index.css";
import "./services/ValidationRules";
import ElementPlus from "element-plus";
import VueSidebarMenu from "vue-sidebar-menu";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import vuescroll from "vuescroll";

// import VueNoty from "vuejs-noty";
// import "vuejs-noty/dist/vuejs-noty.css";

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.use(VueSidebarMenu);
app.use(VueSweetalert2);
// app.use(VueNoty, {
//   timeout: 4000,
//   progressBar: true,
//   layout: "topRight",
// });
app.use(vuescroll, {
  ops: {
    bar: {
      showDelay: 5000,
      onlyShowBarOnScroll: false,
      keepShow: false,
      background: "#c51f4b",
      opacity: 1,
      specifyBorderRadius: false,
      minSize: 0,
      size: "10px",
      disable: false,
    },
  },
});
app.mount("#app");

app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$shareService = shareService;
app.config.globalProperties.$api = api;

document.title = IS_ADMIN ? 'Pantrio :: ADMIN' : 'Pantrio :: MERCHANT'

app.directive("click-outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
