import { createStore } from "vuex";
const store = createStore({
  state() {
    return {
      user: {},
      id: "",
      uuid: "",
      vehicleTab: "",
      orderTab: "",
      title: "",
      isHideMenu: false,
      vendorDetailsTab: "",
      expenseType: "",
      expenseId: "",
      country_id: "",
      state_id: "",
      product_type_id: "",
      currentTab: "",
      currentpage: "",
      email : "",
      merchant_stripe_account_id: "",
    };
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setEmail(state, payload) {
      state.email = payload;
    },
    setId(state, payload) {
      state.id = payload;
    },

    setStateId(state, payload) {
      state.state_id = payload;
    },
    setCategoryId(state, payload) {
      state.id = payload;
    },
    setProductTypeId(state, payload) {
      state.product_type_id = payload;
    },
    setIsHideMenu(state, payload) {
      state.isHideMenu = payload;
    },
    setVehicleTab(state, payload) {
      state.vehicleTab = payload;
    },
    setUuid(state, payload) {
      state.uuid = payload;
    },
    setOrderTab(state, payload) {
      state.orderTab = payload;
    },
    setVendorDetailsTab(state, payload) {
      state.vendorDetailsTap = payload;
    },
    setExpenseType(state, payload) {
      state.expenseType = payload;
    },
    setExpenseId(state, payload) {
      state.expenseId = payload;
    },
    setCurrentTab(state, payload) {
      state.currentTab = payload;
    },
    setStripeAccountId(state, payload) {
      state.merchant_stripe_account_id = payload;
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getTitle(state) {
      return state.title;
    },
    getId(state) {
      return state.id;
    },
    getCountryId(state) {
      return state.country_id;
    },
    getStateId(state) {
      return state.state_id;
    }, 
    getCategoryId(state) {
      return state.id;
    },
    getProductTypeId(state){
      return state.product_type_id;
    },
    
    getIsHideMenu(state) {
      return state.isHideMenu;
    },
    getVehicleTab(state) {
      return state.vehicleTab;
    },
    getUuid(state) {
      return state.uuid;
    }, 
    getEmail(state) {
      return state.email;
    },
    getOrderTab(state) {
      return state.orderTab;
    },
    getVendorDetailsTab(state) {
      return state.vendorDetailsTap;
    },
    getExpenseType(state) {
      return state.expenseType;
    },
    getExpenseId(state) {
      return state.expenseId;
    },
    getCurrentTab(state) {
      return state.currentTab;
    },
    getStripeAccountId(state) {
      return state.merchant_stripe_account_id;
    },
  },
  actions: {},
});

export default store;
