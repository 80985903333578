import moment from "moment";

// import 'moment-timezone';
// import CryptoJS from "crypto-js";
class HelperService {
  constructor() {
    this.accessLevel = new Map();
  }

  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  getFormatedDate(d) {
    if (!d || d == null) {
      return "";
    }
    return moment(d).format("MMM DD, YYYY");
  }

  getDate(d) {
    if (!d || d == null) {
      return "";
    }
    return moment(d);
  }

  getFormatedDateAndTime(dt) {
    if (!dt || dt == null) {
      return "";
    }
    return moment.utc(dt).local().format("DD MMM YYYY, hh:mm A");
  }
  getFormatedYear(dt) {
    if (!dt || dt == null) {
      return "";
    }
    return moment.utc(dt, "DD MMM YYYY, hh:mm A").local().format("YYYY");
  }

  getTimeFromDate(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  getFormatedTime(dt) {
    if (!dt || dt == null) {
      return "";
    }
    return moment.utc(dt).local().format("hh:mm A");
  }

  getFormattedTimeIST(dt) {
    if (!dt || dt == null) {
      return "";
    }
    return moment(dt).tz('Asia/Kolkata').format('hh:mm A');
  }
  
  getFormatingDateTime(date) {
    if (!date) {
      return "";
    }
    if (!this.config) {
      this.config = {};
    }

    date = moment(date, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("DD MMM YYYY, hh:mm A");

    return date;
  }

  sendFormatedDate(d, type) {
    if (type == "date") {
      return moment(d).format("YYYY-MM-DD");
    } else if (type == "month_year") {
      return moment(d).format("YYYY-MM");
    } else if (type == "year") {
      return moment(d).format("YYYY");
    } else {
      return moment(d).format("YYYY-MM-DD");
    }
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }
  allowOnlyTextValue(e) {
    var numbers = /^[a-zA-Z ]+$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }

  getTitleCase(value) {
    if (!value) {
      return "";
    } else {
      var str = "";
      var arr = value.split("_");
      for (var i in arr) {
        if (i == 0) {
          str =
            arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
        } else {
          str +=
            " " +
            arr[i].charAt(0).toUpperCase() +
            arr[i].substring(1).toLowerCase();
        }
      }
      return str;
    }
  }
  setAccessLevel(accessLevel) {
    this.accessLevel = accessLevel;
    var user_id = localStorage.getItem("id");
    var originalText = JSON.stringify(accessLevel);
    localStorage.setItem(user_id + "_accesses", originalText);
  }

  getAccessLevel(moduleName) {
    var user_id = localStorage.getItem("id");
    var originalText = localStorage.getItem(user_id + "_accesses");
    this.accessLevel = JSON.parse(originalText);
    if (this.accessLevel.length > 0) {
      for (var i = 0; i < this.accessLevel.length; i++) {
        if (moduleName == this.accessLevel[i].name) {
          return this.accessLevel[i];
        }
      }
    }
  }

  // getLeftMenuAccess(moduleName) {
  //   if (this.accessLevel.length > 0) {
  //     for (var i = 0; i < this.accessLevel.length; i++) {
  //       if (moduleName == this.accessLevel[i].name) {
  //         return this.accessLevel[i].is_view;
  //       }
  //     }
  //   }
  // }
  // setAccessLevel(accessLevel) {
  //   this.accessLevel = accessLevel;
  //   var user_id = localStorage.getItem("id");
  //   var ciphertext = JSON.stringify(this.accessLevel);
  //   var bytes = CryptoJS.AES.encrypt(ciphertext, "vino@123##!Sdff23343");
  //   localStorage.setItem(user_id + "_accesses", bytes);
  // }

  // getAccessLevel(moduleName) {
  //   var user_id = localStorage.getItem("id");
  //   var bytes = CryptoJS.AES.decrypt(
  //     localStorage.getItem(user_id + "_accesses"),
  //     "vino@123##!Sdff23343"
  //   );
  //   var originalText = bytes.toString(CryptoJS.enc.Utf8);
  //   this.accessLevel = JSON.parse(originalText);
  //   if (this.accessLevel.length > 0) {
  //     for (var i = 0; i < this.accessLevel.length; i++) {
  //       if (moduleName == this.accessLevel[i].name) {
  //         return this.accessLevel[i];
  //       }
  //     }
  //   }
  // }

  allowDecimalValue(e) {
    var charCode = e.which ? e.which : e.keyCode;
    var number = e.target.value.split(".");
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      e.preventDefault();
    }
    //get the carat position
    var caratPos = this.getSelectionStart(e.target);
    var dotPos = e.target.value.indexOf(".");
    if (caratPos > dotPos && dotPos > -1 && number[1].length > 1) {
      e.preventDefault();
    }
  }

  getSelectionStart(o) {
    if (o.createTextRange) {
      var r = document.selection.createRange().duplicate();
      r.moveEnd("character", o.value.length);
      if (r.text == "") return o.value.length;
      return o.value.lastIndexOf(r.text);
    } else return o.selectionStart;
  }

  hasAccessLevel() {
    return this.accessLevel.length > 0;
  }

  formatPrice(value) {
    if (!value) {
      return 0.0;
    }
    return Intl.NumberFormat("en-US").format(value);
  }
  getText(text, length) {
    if (text && length && text.length > length) {
      return text.substring(0, length) + "...";
    }
    return text;
  }

  getTimeDifference(in_time) {
    in_time = this.getFormatedDateAndTime(in_time);
    var out_time = this.getFormatedDateAndTime(new Date());
    if (in_time && out_time && in_time < out_time) {
      var dateA = new Date(in_time);
      var dateB = new Date(out_time);
      var time = (dateB.getTime() - dateA.getTime()) / 1000;
      if (time > 0 && time < 60) {
        return Math.floor(time) + " secs ago";
      } else if (time > 60 && time < 3600) {
        time = time / 60;
        if (time > 1 && time < 2) {
          return Math.floor(time) + " min ago";
        } else {
          return Math.floor(time) + " mins ago";
        }
      } else if (time > 3600 && time < 86400) {
        time = time / 3600;
        if (time > 1 && time < 2) {
          return Math.floor(time) + " hr ago";
        } else {
          return Math.floor(time) + " hrs ago";
        }
      } else if (time > 86400) {
        time = time / 86400;
        if (time > 1 && time < 2) {
          return Math.floor(time) + " day ago";
        } else {
          return Math.floor(time) + " days ago";
        }
      }
    }
  }
}

export default new HelperService({});