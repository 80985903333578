import { defineRule } from "vee-validate";

defineRule("required", (value, [name]) => {
  if ((!value && value != 0) || value.length <= 0) {
    if (name) {
      return `The ${name}  is required`;
    }
    return "This  is required";
  }
  return true;
});

defineRule('website', value => {
  if (value && !/^((http|https):\/\/.)(ww\.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,15}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/g.test(value)) {
      return 'Please enter valid URL';
  }

  return true;
});

defineRule("email", (value) => {
  if (
    value &&
    !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(value)
  ) {
    return "Please enter valid email";
  }
  return true;
});

defineRule("phone", (value) => {
  if (value && !/^[0-9]{10}$/.test(value)) {
    return "The Phone Number field must be numeric and exactly contain 10 digits";
  }
  return true;
});

defineRule("minLength", (value, [limit]) => {
  if (value.length < limit) {
    return `Phone Number must be at least ${limit} characters`;
  }
  return true;
});


defineRule("maxLength", (value, [limit]) => {
  if (value.length > limit) {
    return `This field must be at least ${limit} characters`;
  }
  return true;
});

defineRule("password", (value) => {

  if (value && !/(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/.test(value)) {
    return "A password should contain at least one upper case, one lower case, and one number. and one special character";
  }
  return true;
});

defineRule("confirmed", (value, [target]) => {
  if (value === target) {
    return true;
  }
  return "Passwords must match";
});

defineRule("price", (value) => {
  if (value && !/^[1-9]\d*(\.\d{1,2}?)?$/.test(value)) {
    return "Please enter valid value";
  }

  return true;
});

defineRule("number", (value) => {
  if (value && !/^[1-9]\d*(\d{1,2}?)?$/.test(value)) {
    return "Please enter valid value";
  }
  return true;
});

defineRule("instagram", (value) => {
  if (
    value &&
    !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(
      value
    )
  ) {
    return "Please enter valid instagram url";
  }
  return true;
});

defineRule("facebook", (value) => {
  if (
    value &&
    !/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)?$/.test(
      value
    )
  ) {
    return "Please enter valid facebook url";
  }
  return true;
});


defineRule("fromDate", (value, [target]) => {
  if (!value || (target && target > value)) {
    return "Please enter valid value";
  }
  return true;
});

defineRule("toDate", (value, [target]) => {
  if (!value || (target && target < value)) {
    return "Please enter valid value";
  }
  return true;
});