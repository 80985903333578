class ShareService {
  constructor() {
    this.map = new Map()
  }

  setMapValue(key, value) {
    this.map.set(key, value)
  }

  getMapValue(key) {
    if (this.map.has(key)) {
      return this.map.get(key)
    } else {
      return null
    }
  }
  getMapValueAndRemove(key) {
    if (this.map.has(key)) {
      var temp = this.map.get(key);
      this.deleteMapValue(key);
      return temp;
    } else {
      return null
    }
  }
  clearMap() {
    this.map.clear()
  }

  deleteMapValue(key) {
    if (this.map.has(key)) {
      this.map.delete(key)
    }
  }

  getStoreType() {
    return this.type
  }

  setStoreType(type) {
    this.type = type;
  }


  removeAllPageData() {
    for (const [key] of this.map.entries()) {
      if (key.indexOf("page_") != -1) {
        this.deleteMapValue(key);
      }
    }
  }
}

export default new ShareService({
  map: new Map()
})